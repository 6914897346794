@import 'variables.scss';

.header {
  position: fixed;
  top: 0;
  padding: $space-sm 0;
  z-index: $z-index-header;
  background-color: $color-white;
  width: 100%;
  box-shadow: 0 3px 9px transparentize($color-text, 0.9);
}

.header__placeholder {
  height: 7.5rem;
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: $screen-md) {
  .header {
    width: 100%;
  }

  .header__placeholder {
    height: 10rem;
  }
}
