@import 'variables.scss';

.logo {
  max-width: 25rem;
}

@media screen and (min-width: $screen-sm) {
  .logo {
    max-width: 38rem;
  }
}
