@import 'variables.scss';

.leaderboard {
  margin: 0 -#{$space-sm};
  border-bottom: 1px solid darken($color-subtle-bg, 5%);
}

.leaderboard__content {
  width: 100%;
  background-color: $color-white;
  padding: $space-lg $space-sm;
}

@media screen and (min-width: $screen-md) {
  .leaderboard {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    min-height: 50vh;
    padding: $space-xl 0;
    margin: 0;
    width: 100%;
  }

  .leaderboard__content {
    padding: $space-xl;
    width: 30%;
    max-width: 40%;
    border-radius: $border-radius-default;
  }

  .leaderboard__image {
    display: none;
  }
}
