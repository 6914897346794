@import './variables.scss';

$close-icon-size: 6rem;

.video-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize($color-text, 0.1);
  text-align: center;
  z-index: $z-index-video-overlay;
}

.video-overlay__close {
  position: absolute;
  top: $space-sm;
  right: $space-sm;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: $close-icon-size;
  height: $close-icon-size;
  border-radius: $close-icon-size / 2;
  background-color: $color-text;
  color: $color-white;
  font-size: $close-icon-size / 3.75;
  cursor: pointer;
  z-index: $z-index-video-overlay-close;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: lighten($color-text, 5%);
  }
}

.video-overlay__video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.video-overlay__video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  flex: 1;
}

.video-overlay__video iframe,
.video-overlay__video object,
.video-overlay__video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
