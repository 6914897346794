@import 'variables.scss';

.menu__items {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
}

.menu__item {
  padding: 0;
  margin-left: $space-lg;
}

.menu__link,
.menu__link:visited {
  color: $color-text;
  font-weight: bold;

  &:hover,
  &:focus {
    color: $color-text;
  }
}
