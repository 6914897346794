@import 'variables.scss';

$play-icon-size: 8rem;

.video-thumbnail {
  display: block;
  position: relative;
  cursor: pointer;
}

.video-thumbnail__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - #{$play-icon-size / 2});
  top: calc(50% - #{$play-icon-size / 2});
  width: $play-icon-size;
  height: $play-icon-size;
  border-radius: $play-icon-size / 2;
  background-color: $color-white;
  font-size: $play-icon-size / 3.75;
  color: $color-text;
  transition: background-color 0.1s ease;

  .video-thumbnail:hover & {
    background-color: darken($color-white, 5%);
  }
}
