.button,
.button:visited,
.button:active {
  display: inline-block;
  border: none;
  background-color: $color-primary;
  padding: $space-sm $space-md;
  font-size: 1.6rem;
  cursor: pointer;
  color: $color-white !important;
  text-decoration: none;
  border-radius: $border-radius-default;
  transition: background-color 0.1s ease;
}

.button:focus,
.button:hover {
  background-color: darken($color-primary, 6);
  color: $color-white !important;
  text-decoration: none;
}

.button__icon {
  margin-right: $space-xs;
}
