@import 'variables.scss';

html {
  font-size: 62.5%;
  overflow-x: hidden;
  max-width: 100vw;
}

body {
  margin: 0;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  color: $color-text;
  line-height: 1.5;
  overflow-x: hidden;
  max-width: 100vw;
}

h1,
h2 {
  font-weight: 900;
  line-height: 1.2;
}

h2 {
  font-size: 2.6rem;
  margin: 0 0 $space-md;
}

p {
  margin-bottom: $space-md;
}

.margin-top--xl {
  margin-top: $space-xl;
}

a,
a:visited {
  color: $color-primary;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-primary;
    text-decoration: underline;
  }
}

ul {
  padding-left: 2rem;
}

img {
  width: 100%;
}

.is-hidden {
  display: none !important;
}

.secondary {
  color: #777;
  font-size: 1.1em;
}

.image {
  border-radius: $border-radius-default;
}
