@import 'variables.scss';

.drives {
  border: 1px solid $color-border;
  border-radius: $border-radius-default;
  padding: $space-md;
}

.drives__drive {
  display: flex;
  margin-bottom: $space-md;

  &:last-child {
    margin-bottom: 0;
  }
}

.drives__drive-icon {
  width: 1.8rem;
  height: 2.4rem;
  margin-right: $space-sm;
}

.drives__drive-text {
  color: $color-subtle-text;
}
