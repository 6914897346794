@import 'variables.scss';

.container {
  padding: 0 $space-sm;
}

@media screen and (min-width: $screen-md) {
  .container {
    margin: 0 auto;
    max-width: 1024px;
    padding: 0 $space-lg;
  }
}
