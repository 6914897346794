@import 'variables.scss';

[class*='block__column'] {
  margin-top: $space-md;
}

@media screen and (min-width: $screen-md) {
  .block {
    display: flex;
  }

  @for $i from 1 through 12 {
    .block__column--#{$i} {
      width: calc(#{100 * ($i / 12)}% - #{$space-xl / 2});
    }
  }

  [class*='block__column'] {
    margin-right: $space-xl;

    &:last-child {
      margin-right: 0;
    }
  }
}
