$space-xs: 0.8rem;
$space-sm: 1.6rem;
$space-md: 2.4rem;
$space-lg: 3.2rem;
$space-xl: 4rem;
$space-xxl: 8rem;

$screen-sm: 420px;
$screen-md: 920px;

$color-text: #111;
$color-dark: #222;
$color-subtle-text: #555;
$color-subtle-bg: #f6f6f6;
$color-border: #d6d6d6;
$color-grey: #aaa;
$color-white: #fff;
$color-primary: #e49a31;

$border-radius-default: 5px;

$z-index-header: 1;
$z-index-video-overlay: 2;
$z-index-video-overlay-close: 3;
