@import 'variables.scss';

input[type='text'],
input[type='email'],
textarea {
  background-color: $color-white;
  border: none;
  padding: $space-sm;
  font-size: 1.6rem;
  width: 100%;
  margin: 0;
  border-radius: $border-radius-default;
}

textarea {
  height: 20rem;
}

.form-control {
  display: block;
  margin-bottom: $space-md;
}

.form-label {
  display: block;
  margin-bottom: $space-xs;
}

@media screen and (min-width: $screen-md) {
  .form-control-pair {
    display: flex;
    width: 100%;

    .form-control {
      flex-grow: 1;
      width: calc(50% - #{$space-sm});
      margin-right: $space-md;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
