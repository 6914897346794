@import 'variables.scss';

.section {
  margin-top: $space-xl;
  position: relative;
}

.section--dark {
  background-color: $color-dark;
  color: $color-white;
}

.section--grey {
  background-color: $color-subtle-bg;
}

.section--dark,
.section--grey {
  padding: $space-lg 0;
}

.section--no-margin {
  margin-top: 0;
}

@media screen and (min-width: $screen-md) {
  .section--bg {
    background-position: center 20%;
    background-size: cover;
  }

  .section {
    margin-top: $space-xxl;
  }

  .section--dark,
  .section--grey {
    padding: $space-xl 0;
  }

  .section--no-margin {
    margin-top: 0;
  }
}
